import * as React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

export const Sketch5 = ({ className }) => {
  return (
    <svg
      width="68"
      height="14"
      viewBox="0 0 68 14"
      fill="none"
      className={cl('sketch5', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 7.85096C3.70637 8.55331 5.61017 8.83245 7.433 8.76174C11.2338 8.61499 16.5092 6.84725 19.4218 3.46021C20.0992 2.67243 22.1944 2.7902 22.7092 3.69267C23.3204 4.76404 24.1278 5.7947 25.208 6.76972C31.0148 12.0098 38.0847 10.5308 41.5492 4.21046C41.981 3.42268 43.2271 3.37548 43.6697 4.15726C44.8404 6.22509 46.2914 8.09174 48.2325 9.65618C54.5182 14.722 61.942 10.8741 66 4.7872"
        stroke="#7569BB"
        strokeWidth="3"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Sketch5.propTypes = {
  className: PropTypes.string
};
