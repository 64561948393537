/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { headingStyles } from './sectionStyles';

const Container = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 24px;
  padding: 32px 16px;
  margin: 0 auto;

  ${headingStyles}

  ${fromScreen(458)} {
    max-width: 425px;
    padding: 32px 0;
  }

  ${fromScreen(776)} {
    row-gap: 40px;
    max-width: 712px;
    padding: 80px 0;

    &.first {
      padding: 100px 0 80px;
    }

    &.last {
      padding: 80px 0 100px;
    }
  }

  ${fromScreen(1144)} {
    max-width: 1080px;
  }
`;

export const HallOfFameSection = props => {
  const { title, content, first = false, last = false } = props;

  return (
    <Container className={cl('hof-section', { first, last })}>
      <h2 className="section-heading">{title}</h2>
      {content}
    </Container>
  );
};
