import { apis } from '../apis/api.config';
import { apiUrls } from '../apis/api.urls';

export const getListedBlogs = async params => {
  const { data } = await apis.publicService.get(
    apiUrls.publicService.GET_LISTED_IELTS_BLOGS_LANDING,
    {
      params
    }
  );

  return data;
};

export const getBlogOverview = async params => {
  const { data } = await apis.publicService.get(
    apiUrls.publicService.GET_BLOGS_OVERVIEW,
    {
      params
    }
  );

  return data;
};
