/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@dolstaff/shared/es/Button';
import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { Typography } from '../../components/DesignSystemV2/Typography';

const ActionLink = styled.a`
  .btn-action {
    border-radius: 8px;
  }
`;

export const HallOfFameActionLink = props => {
  const { url, text, onClickFunc } = props;

  return (
    <ActionLink
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="action-link"
    >
      <Button
        size="large"
        className="btn-action"
        type="primary"
        marginBottom="0"
        onClick={onClickFunc}
      >
        <Typography variant="semi-bold/14-24" color={colorsV2.white100}>
          {text}
        </Typography>
      </Button>
    </ActionLink>
  );
};
