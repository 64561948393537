import * as React from 'react';

import MainLayout from 'components/Layout/MainLayout';
import MySEO from 'shared/MySEO';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import StudentReviews from 'shared/StudentCourseLanding/StudentReviews';
import HallOfFame from 'shared/HallOfFame';

import { HallOfFameBanner } from '../components/HallOfFame/HallOfFameBanner';
import { HallOfFameActionLink } from '../shared/HallOfFame/HallOfFameActionLink';
import DolInfo from '../shared/DolInfo';
import { HallOfFameSection } from '../components/HallOfFame/HallOfFameSection';
import { StudentStoryList } from '../components/StudentStory/StudentStoryList';
import { useInfiniteQuery } from 'react-query';
import { apiCacheKeys } from '../apis/api.urls';
import { getListedBlogs } from '../service/blogs-service';
import { ButtonLoadMore } from '../components/ButtonLoadMore/ButtonLoadMore';
import { FooterEndSection } from '../components/FooterEndSection/FooterEndSection';
import { BLOG_TYPE, SORT_BY_ORDERING_ASC } from '../constant';
import { UserPixelTracking } from 'utils/userPixelTracking';

const listingParams = {
  blogType: BLOG_TYPE.STUDENT_STORY,
  size: 6,
  sort: SORT_BY_ORDERING_ASC
};

const HallOfFamePage = ({ location }) => {
  const {
    data: listingData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    [apiCacheKeys.publicService.GET_LISTED_IELTS_BLOGS_LANDING, listingParams],
    ({ pageParam = 0 }) =>
      getListedBlogs({
        ...listingParams,
        page: pageParam
      }),
    {
      getNextPageParam: lastPage => {
        const { number, last = false } = lastPage;
        if (last) {
          return undefined;
        }
        if (isNaN(number)) {
          return 0;
        }
        return number + 1;
      }
    }
  );

  const onClick = () => {
    UserPixelTracking.clickEvent();
  };

  return (
    <MainLayout showFooterRegister hideSideCTA>
      <FixedTopBarScholarshipScheduleNOffline />

      <MySEO
        slug={location.pathname}
        h1="Thành tích và cảm nhận của học viên"
        hidePostfix
        title="Cảm nhận của Học Viên - DOL English"
        description="Những thành tích và cảm nhận của học viên về khóa học tiếng Anh tại DOL là mình chứng rõ ràng nhất cho hiệu quả của phương pháp giảng dạy áp dụng Linearthinking"
      />

      <HallOfFameBanner
        title={`Thành tích và cảm nhận của <span class="highlight">học viên</span>`}
        description="Hàng ngàn review dài tám cây số chính là niềm tự hào, là động lực của DOL và cũng là tài sản nhỏ, tích góp, lụm lặt mỗi ngày của DOL."
        ctaAction={
          <DolInfo>
            {({ dolInfo }) => (
              <HallOfFameActionLink
                url={dolInfo.messenger.href}
                text="Nhắn tin DOL qua FB"
                onClickFunc={onClick}
              />
            )}
          </DolInfo>
        }
      />
      <HallOfFameSection
        title="Bảng vàng thành tích của DOL"
        content={<HallOfFame />}
        first
      />
      <HallOfFameSection
        title="Tâm tình học viên dành cho DOL"
        content={
          <StudentReviews
            program="all"
            columns={{ 775: 1, 1143: 2, default: 3 }}
            noTitle
          />
        }
      />
      {Boolean(listingData?.pages?.[0]?.numberOfElements) && (
        <HallOfFameSection
          title="Câu chuyện học viên"
          content={
            <StudentStoryList
              data={listingData?.pages.flatMap(p => p.content)}
              loadMoreButton={
                hasNextPage ? (
                  <ButtonLoadMore
                    text="Xem thêm bài viết"
                    loading={isFetchingNextPage}
                    onClick={fetchNextPage}
                  />
                ) : (
                  <FooterEndSection message="Bạn đã xem hết bài viết" />
                )
              }
            />
          }
        />
      )}
    </MainLayout>
  );
};

export default HallOfFamePage;
