import * as React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

export const Sketch1 = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={cl('sketch1', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0311 22.4993C11.2119 22.5202 10.5436 22.0396 10.4574 21.4337C10.3927 20.9531 10.3927 20.4621 10.4035 19.9815C10.4682 17.8815 9.37951 16.2517 8.12919 14.7055C7.57948 14.0264 6.74953 13.713 5.84413 13.6921C5.05729 13.6712 4.25967 13.6816 3.47283 13.6712C3.11714 13.6607 2.75067 13.6607 2.40576 13.5876C1.94228 13.4936 1.51112 12.9712 1.50034 12.5324C1.48956 12.0727 1.73747 11.6966 2.14705 11.5399C3.21414 11.1429 4.28123 10.7145 5.39143 10.4742C7.69805 9.97273 8.89447 8.42649 9.63819 6.44145C9.79987 6.02355 9.70287 5.50118 9.69209 5.03104C9.67053 4.13255 9.58431 3.23408 9.61664 2.33559C9.62742 1.89679 9.96155 1.57291 10.4466 1.51022C10.9316 1.44754 11.3089 1.67738 11.4813 2.08484C11.7616 2.78482 12.0634 3.5057 12.2035 4.24747C12.9257 7.93545 16.3964 10.5683 20.2767 10.328C20.6324 10.3071 20.9881 10.2235 21.3438 10.2444C21.7857 10.2653 22.1953 10.4533 22.3785 10.8817C22.5941 11.3727 22.5294 11.8742 22.1306 12.2294C21.8288 12.501 21.43 12.7204 21.0312 12.8249C18.0779 13.5771 16.1054 15.5831 14.2838 17.7457C13.8203 18.2994 13.7125 19.1457 13.4646 19.8665C13.249 20.5247 13.1413 21.2247 12.8395 21.8411C12.6993 22.1546 12.2359 22.3426 12.0311 22.4993ZM16.1593 12.3966C15.1892 11.7801 14.3269 11.31 13.5508 10.7354C12.7856 10.1608 12.0957 9.48171 11.3412 8.81307C10.4035 9.86827 9.5412 10.8399 8.60346 11.8951C9.97235 12.7204 10.7484 13.8801 11.4921 15.0711C11.6969 15.395 11.9987 15.6562 12.3221 16.0219C13.0227 15.3219 13.6155 14.7264 14.2299 14.1413C14.8443 13.5667 15.4694 13.0234 16.1593 12.3966Z"
        fill="#FFAD4C"
      />
    </svg>
  );
};

Sketch1.propTypes = {
  className: PropTypes.string
};
