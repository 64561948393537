import * as React from 'react';

import { Typography } from '../../components/DesignSystemV2/Typography';

export const H1 = props => {
  return (
    <Typography
      type="span"
      className="text"
      variant="bold/32-40"
      desktopVariant="bold/40-48"
      as="h1"
      {...props}
    />
  );
};

export const H2 = props => {
  return (
    <Typography
      type="span"
      className="text"
      variant="bold/24-32"
      desktopVariant="bold/32-40"
      as="h2"
      {...props}
    />
  );
};

export const Text = props => {
  return (
    <Typography
      type="span"
      className="text"
      variant="regular/16-24"
      desktopVariant="regular/16-24"
      {...props}
    />
  );
};
