import { css } from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { fromScreen } from '../../utils/media-query/responsive.util';

export const headingStyles = css`
  .heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: ${colorsV2.black100};
    margin: 0;

    .highlight {
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        background: ${colorsV2.yellow100};
        bottom: 0;
        left: 0;
        height: 12px;
        width: 100%;
        opacity: 0.35;
        z-index: -1;
        bottom: 3px;
      }
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colorsV2.black80};
  }

  ${fromScreen(458)} {
    .heading {
      font-size: 40px;
      line-height: 48px;

      .highlight {
        &::after {
          bottom: 3px;
        }
      }
    }
  }

  ${fromScreen(1144)} {
    .heading {
      font-size: 48px;
      line-height: 58px;

      .highlight {
        &::after {
          bottom: 7px;
        }
      }
    }

    .description {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const iconStyles = css`
  position: relative;

  .banner-icon {
    display: none;
  }

  .star-icon {
    position: absolute;
    width: 28px;
    height: 28px;
  }

  .spinning-arrow-icon {
    position: absolute;
    width: 58px;
    height: 24px;
    transform: matrix(-0.74, -0.67, -0.67, 0.74, 0, 0);
  }

  .wave-icon {
    position: absolute;
    width: 64px;
    height: 12px;
  }

  ${fromScreen(776)} {
    .banner-icon {
      &.star-icon {
        display: block;
      }
    }

    .star-icon {
      top: 2px;
      right: 14px;
    }
  }

  ${fromScreen(1144)} {
    .banner-icon {
      display: block;
    }

    .star-icon {
      top: 21px;
      right: 51px;
    }

    .spinning-arrow-icon {
      top: 448.85px;
      left: 77.8px;
    }

    .wave-icon {
      left: 624px;
      top: 362px;
    }
  }
`;
