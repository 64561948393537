/* eslint-disable react/prop-types */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ProgressiveImage from 'react-progressive-image';
import styled from 'styled-components';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { colorsV2 } from '../../style/colors-v2';
import { Sketch1 } from '../Sketch/Sketch1';
import { Sketch4 } from '../Sketch/Sketch4';
import { Sketch5 } from '../Sketch/Sketch5';
import { headingStyles, iconStyles } from './bannerStyles';

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content 1fr;
  align-items: flex-start;
  row-gap: 20px;

  ${fromScreen(1144)} {
    row-gap: 32px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content 1fr;
  align-items: flex-start;
  row-gap: 24px;

  ${fromScreen(1144)} {
    row-gap: 36px;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Layout = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  column-gap: 20px;

  margin: 0 auto;

  .banner-image {
    display: none;
  }

  ${iconStyles}

  ${fromScreen(458)} {
    max-width: 425px;
    min-height: 180px;
  }

  ${fromScreen(776)} {
    grid-template-columns: minmax(0, 1fr) 300px;
    max-width: 712px;

    .banner-image {
      display: block;
    }
  }

  ${fromScreen(1144)} {
    grid-template-columns: minmax(0, 1fr) 436px;
    column-gap: 68px;
    max-width: 1080px;
  }
`;

const Container = styled.div`
  padding: 32px 16px;
  background-color: ${colorsV2.paper};

  ${headingStyles}

  ${fromScreen(458)} {
    padding: 32px 0;
  }

  ${fromScreen(776)} {
    padding: 100px 0;
  }
`;

const useHallOfFameBanner = () => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "HallOfFameLanding_Banner" }) {
          content
        }
      }
    }
  `);
  return data.gcms.jsonContanier.content;
};

export const HallOfFameBanner = props => {
  const { ctaAction } = props;
  const { title, description, imgHandle } = useHallOfFameBanner();

  return (
    <Container className="hall-of-fame-banner">
      <Layout>
        <Content>
          <TextContainer>
            <h1
              className="heading"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p className="description">{description}</p>
          </TextContainer>
          {ctaAction}
        </Content>
        <ProgressiveImage
          src={getAssetLinkByHandle({ handle: imgHandle })}
          placeholder={getAssetLinkByHandle({
            handle: imgHandle,
            width: 10
          })}
        >
          {src => (
            <Image
              className="banner-image"
              src={src}
              alt="hall-of-fame-banner"
            />
          )}
        </ProgressiveImage>
        <Sketch1 className="banner-icon star-icon" />
        <Sketch4 className="banner-icon spinning-arrow-icon" />
        <Sketch5 className="banner-icon wave-icon" />
      </Layout>
    </Container>
  );
};
